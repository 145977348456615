import { FETCH_TOURS } from 'constants/actionTypes';

const INITIAL_TOURS_DATA = {};

const toursReducer = (state = INITIAL_TOURS_DATA, action) => {
  switch (action.type) {
    case FETCH_TOURS: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export default toursReducer;
